<template>
  <div class="template-sms">
    <div
      v-if="loading && !getSmsTemplateLoading"
      class="init-loading three-dots-loading"
    >
      Chargement en cours
    </div>
    <div v-else>
      <b-list-group>
        <div v-if="getAllTemplateSms && getAllTemplateSms.length > 0">
          <b-list-group-item
            v-for="smsTemplate in getAllTemplateSms"
            :key="smsTemplate.id"
            ><div class="content-email-template">
              <div class="title">{{ smsTemplate.title }}</div>
              <div class="btn-action">
                <b-button
                  size="sm"
                  variant="secondary"
                  title="Visualiser"
                  @click.prevent.stop="handleShowClick(smsTemplate)"
                >
                  <font-awesome-icon class icon="eye" />
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  title="Modifier"
                  @click.prevent.stop="handleUpdateClick(smsTemplate)"
                >
                  <font-awesome-icon class icon="pencil-alt" />
                </b-button>
                <b-button
                  size="sm"
                  title="Supprimer"
                  variant="danger"
                  @click.prevent.stop="handleDeleteClick(smsTemplate)"
                >
                  <font-awesome-icon class icon="trash-alt" />
                </b-button>
              </div></div
          ></b-list-group-item>
        </div>
        <b-list-group-item
          class="aucun-template-sms"
          v-if="(!getAllTemplateSms || !getAllTemplateSms.length) && !loading"
          >Aucun templates sms</b-list-group-item
        >
      </b-list-group>
    </div>

    <!-- delete sms template -->
    <b-modal
      no-close-on-backdrop
      ref="deleteSmsTemplate"
      id="deleteSmsTemplate"
      title=" Supprimer une Template sms"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <p>
        Êtes-vous certain de vouloir supprimer
        <strong>
          {{ TemplateSmsToDelete ? TemplateSmsToDelete.title : '' }}</strong
        >
        ?
      </p>
      <div class="message">
        <div v-if="getSmsTemplateLoading" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getTemplateSmsError" class="error">
          <ul v-if="Array.isArray(getTemplateSmsError)">
            <li v-for="(e, index) in getTemplateSmsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getTemplateSmsError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          variant="outline-secondary"
          @click="hideModal('deleteSmsTemplate')"
        >
          Annuler
        </b-button>
        <b-button variant="success" @click="supprimerTemplateSms">
          Valider
        </b-button>
      </div>
    </b-modal>
    <!-- update sms template  -->
    <Modal
      title="Modifier une template SMS"
      modalClass="ModelClass"
      v-model="showTemplateSmsToUpdate"
    >
      <b-row class="row mb-1" v-if="TemplateSmsToUpdate">
        <!-- Title -->
        <b-col col xs="12" sm="6" md="6">
          <b-form-group label="Titre * " label-for="title-template-sms-input">
            <b-form-input
              ref="title-template-sms-input"
              id="title-template-sms-input"
              v-model="TemplateSmsToUpdate.title"
              required
            ></b-form-input>
          </b-form-group>
          <!-- Objet  -->

          <b-form-group label="Message * " label-for="title-template-sms-input">
            <b-form-textarea
              id="textarea"
              v-model="TemplateSmsToUpdate.message"
              rows="7"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <!-- Fields -->
        <b-col col xs="12" sm="6" md="6">
          <div class="mb-1 titleFields">
            Champs
            <div class="CopieMsg" v-if="copierText">Text copier</div>
          </div>
          <div
            v-for="(prop, val) of getSmsTemplatesFields"
            :key="prop.id"
            @click="CopyFields({ prop: prop, val: val })"
            class="filed-class"
            title="Copier"
          >
            <div class="icon-field">☰</div>
            <div class="value-field">{{ val }}</div>
            <div class="icon-copy">
              <font-awesome-icon icon="copy" />
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="message">
        <div v-if="getTemplateSmsError" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getTemplateSmsError" class="error">
          <ul v-if="Array.isArray(getTemplateSmsError)">
            <li v-for="(e, index) in getTemplateSmsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getTemplateSmsError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModalTemplete('showTemplateSmsToUpdate')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>

        <b-button
          variant="success"
          class="btn-valider"
          @click="handleUpdateSmsTemplate"
        >
          Enregistrer
        </b-button>
      </div>
    </Modal>
    <!--show template -->
    <b-modal
      no-close-on-backdrop
      ref="showSmsTemplateModal"
      id="showSmsTemplateModal"
      :title="
        smsTemplateToShow && smsTemplateToShow.title
          ? smsTemplateToShow.title
          : ''
      "
      :hide-footer="true"
      size="lg"
      @hidden="resetModal"
    >
      <div v-if="smsTemplateToShow" v-html="getSmsToShow"></div>
      <div class="form-actions">
        <b-button
          variant="outline-secondary"
          @click="hideModal('showSmsTemplateModal')"
        >
          Fermer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueModal from '@kouts/vue-modal'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      TemplateSmsToDelete: null,
      TemplateSmsToUpdate: null,
      showTemplateSmsToUpdate: false,
      copierText: false,
      open: false,
      smsTemplateToShow: null,
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'fetchAllSmsTemplates',
      'resetErrorSmsTemplate',
      'deleteTemplateSms',
      'updateSmsTemplate'
    ]),
    resetModal() {
      this.TemplateSmsToDelete = null
      this.TemplateSmsToUpdate = null
      this.smsTemplateToShow = null
      this.resetErrorSmsTemplate()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    hideModalTemplete(template) {
      this[template] = false
      this.resetModal()
    },
    CopyFields(payload) {
      const el = document.createElement('textarea')
      el.value = payload.prop
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)
      this.copierText = true
      setTimeout(() => {
        this.copierText = false
      }, 800)
    },
    handleDeleteClick(template) {
      this.TemplateSmsToDelete = template
      this.$refs['deleteSmsTemplate'].show()
    },
    async supprimerTemplateSms() {
      const response = await this.deleteTemplateSms({
        template: this.TemplateSmsToDelete
      })
      if (response) {
        this.hideModal('deleteSmsTemplate')
      }
    },
    handleUpdateClick(template) {
      this.TemplateSmsToUpdate = template
      this.showTemplateSmsToUpdate = true
    },
    handleUpdateSmsTemplate() {
      if (
        this.TemplateSmsToUpdate &&
        (this.TemplateSmsToUpdate.title == null ||
          this.TemplateSmsToUpdate.title == '')
      ) {
        this.$refs['title-template-sms-input'].reportValidity(
          this.$refs['title-template-sms-input'].validationMessage
        )
      } else {
        const specific_field = []
        if (this.TemplateSmsToUpdate.message != null) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(
            this.getSmsTemplatesFields
          )) {
            if (this.TemplateSmsToUpdate.message.indexOf(value) !== -1) {
              specific_field.push(value)
            }
          }
        }
        this.TemplateSmsToUpdate.specific_field = specific_field
        const response = this.updateSmsTemplate({
          TemplateSmsToUpdate: this.TemplateSmsToUpdate
        })
        if (response) {
          this.hideModalTemplete('showTemplateSmsToUpdate')
        }
      }
    },
    handleShowClick(smsTemplate) {
      //this.open = true
      this.smsTemplateToShow = smsTemplate
      this.$refs['showSmsTemplateModal'].show()
    }
  },
  computed: {
    ...mapGetters([
      'getSmsTemplateLoading',
      'getAllTemplateSms',
      'getTemplateSmsError',
      'getSmsTemplatesFields'
    ]),
    getSmsToShow() {
      return this.smsTemplateToShow.message.replaceAll('\n', '</br>')
    }
  },
  components: {
    Modal: VueModal
  },
  async mounted() {
    this.loading = true
    await this.fetchAllSmsTemplates({
      sort_by: 'full_name'
    })
    this.loading = false
  }
}
</script>

<style scoped lang="scss">
.template-sms {
  .list-group {
    .aucun-template-sms {
      color: #007bff;
      text-align: center;
    }
    .list-group-item {
      .content-email-template {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-weight: bold;
          width: 300px;
        }
        .btn-action {
          display: block;
          margin-right: 20px;
          white-space: nowrap;
          button {
            margin: 0 2px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .list-group {
      width: 111%;
      .list-group-item {
        .content-email-template {
          // display: block;
          .title {
            width: 100%;
          }
          .right {
            // display: block;
            .btn-action {
              display: flex;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .list-group {
      .list-group-item {
        .content-email-template {
          display: block;
          .title {
            width: 100%;
          }
          .right {
            display: block;
            .btn-action {
              display: flex;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.ModelClass {
  border-radius: 5px;
  max-width: 800px;
  width: auto;
  .vm-title {
    font-size: 1.25rem;
  }
}
.filed-class {
  border: 1px solid #6c757d4d;
  background-color: #ebebeb5c;
  border-radius: 4px;
  position: relative;
  padding: 5px;
  width: 318px;
  margin: 4px;
  cursor: pointer;
  .icon-field {
    position: absolute;
    left: 6px;
    width: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .icon-copy {
    position: absolute;
    right: 6px;
    width: 25px;
    top: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .value-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 25px;
  }
}
</style>
